export default {
  name: 'Franklin Bridge Performance Institute',
  siteURL: 'https://franklinbridgegolf.com',
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/fbpi_logo.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#dd0707',
    mobileSignInColor: '#dd0707',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#dd0707',
    accent1Color: '#000',

    primary: {
      main: '#dd0707',
    },
    secondary: {
      main: '#000',
    },
  },
  sideNav: {
    selectedColor: '#dd0707',
  },
  icons: {
    cart: '#dd0707',
  },
  statusBar: {
    backgroundColor: '#dd0707',
  },
};
