export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Franklin Bridge Golf Club',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    "Welcome to our scheduling platform! We are excited to take you on a journey to achieving your goals and desires for this game regardless of your skill set, experience, or interest level. There is something here for everyone and we can't wait to get started with you!",
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
